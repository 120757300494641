var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Enable / Disable")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.points.point_setting,
                expression: "points.point_setting",
              },
            ],
            staticClass: "form-control",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.points,
                  "point_setting",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { attrs: { value: "enable" } }, [_vm._v("Enable")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "disable" } }, [_vm._v("Disabled")]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Check In Points")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.points.checkin_point,
              expression: "points.checkin_point",
            },
          ],
          ref: "checkin_point",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.points.checkin_point },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.points, "checkin_point", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Check In Hours")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.points.checkin_hour,
              expression: "points.checkin_hour",
            },
          ],
          ref: "checkin_hour",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.points.checkin_hour },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.points, "checkin_hour", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Per Point Worth")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.points.per_point,
              expression: "points.per_point",
            },
          ],
          ref: "per_point",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.points.per_point },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.points, "per_point", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Per Order Price Point")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.points.per_order_price_point,
              expression: "points.per_order_price_point",
            },
          ],
          ref: "per_order_price_point",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.points.per_order_price_point },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.points, "per_order_price_point", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Redeem Point")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.points.redeem_point,
              expression: "points.redeem_point",
            },
          ],
          ref: "redeem_point",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.points.redeem_point },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.points, "redeem_point", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Register Point")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.points.register_points,
              expression: "points.register_points",
            },
          ],
          ref: "redeem_point",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.points.register_points },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.points, "register_points", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.updateSetting()
            },
          },
        },
        [_vm._v("\n      Submit\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }