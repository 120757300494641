var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.$parent.permissions.includes("dashboard")
      ? _c(
          "div",
          {
            staticClass: "row",
            staticStyle: {
              "justify-content": "center",
              "align-items": "center !important",
            },
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$parent.permissions.includes("dashboard")
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-6 col-xl-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "card card-custom gutter-b bg-white border-0 theme-circle theme-circle-primary",
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("h3", { staticClass: "text-body font-weight-bold" }, [
                    _vm._v("Orders"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-dark font-weight-bold font-size-h1 mr-3",
                        },
                        [
                          _c("span", { staticClass: "counter" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.totalOrders) +
                                "\n                "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold font-size-h0",
                          class:
                            _vm.OrderPercentage < 0
                              ? "text-danger"
                              : "text-success",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.OrderPercentage) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "svg-icon",
                          class:
                            _vm.OrderPercentage < 0
                              ? "text-danger"
                              : "text-success",
                        },
                        [
                          _c("i", {
                            staticClass: "fas",
                            class:
                              _vm.OrderPercentage < 0
                                ? "fa-arrow-down"
                                : "fa-arrow-up",
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-black-50 mt-3" }, [
                      _vm._v(
                        "\n              Compare to last year (" +
                          _vm._s(_vm.lastYear) +
                          ")\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 col-xl-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "card card-custom gutter-b bg-white border-0 theme-circle theme-circle-warning",
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("h3", { staticClass: "text-body font-weight-bold" }, [
                    _vm._v("Gold & Silver Rates"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("div", [
                      _c("p", { staticClass: "text-dark font-weight-bold" }, [
                        _vm._v("Gold 22kt: ₹" + _vm._s(_vm.gold22ktRate)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-dark font-weight-bold" }, [
                        _vm._v("Silver: ₹" + _vm._s(_vm.silverRate)),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 col-xl-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "card card-custom gutter-b bg-white border-0 theme-circle theme-circle-success",
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("h3", { staticClass: "text-body font-weight-bold" }, [
                    _vm._v("Users"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-dark font-weight-bold font-size-h1 mr-3",
                        },
                        [
                          _c("span", { staticClass: "counter" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.totalCustomers) +
                                "\n                "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold font-size-h0",
                          class:
                            _vm.CustomerPercentage < 0
                              ? "text-danger"
                              : "text-success",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.CustomerPercentage) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "svg-icon",
                          class:
                            _vm.CustomerPercentage < 0
                              ? "text-danger"
                              : "text-success",
                        },
                        [
                          _c("i", {
                            staticClass: "fas",
                            class:
                              _vm.CustomerPercentage < 0
                                ? "fa-arrow-down"
                                : "fa-arrow-up",
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-black-50 mt-3" }, [
                      _vm._v(
                        "\n              Compare to last year (" +
                          _vm._s(_vm.lastYear) +
                          ")\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 col-xl-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "card card-custom gutter-b bg-white border-0 theme-circle theme-circle-info",
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("h3", { staticClass: "text-body font-weight-bold" }, [
                    _vm._v("Sales"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-dark font-weight-bold font-size-h1 mr-3",
                        },
                        [
                          _vm._v("$"),
                          _c("span", { staticClass: "counter" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.totalSales) +
                                "\n                "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold font-size-h0",
                          class:
                            _vm.SalePercentage < 0
                              ? "text-danger"
                              : "text-success",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.SalePercentage) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "svg-icon",
                          class:
                            _vm.SalePercentage < 0
                              ? "text-danger"
                              : "text-success",
                        },
                        [
                          _c("i", {
                            staticClass: "fas",
                            class:
                              _vm.SalePercentage < 0
                                ? "fa-arrow-down"
                                : "fa-arrow-up",
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-black-50 mt-3" }, [
                      _vm._v(
                        "\n              Compare to last year (" +
                          _vm._s(_vm.lastYear) +
                          ")\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$parent.permissions.includes("dashboard")
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-6 col-xl-8" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "card-body pt-3" }, [
                  _c(
                    "div",
                    { attrs: { id: "chart" } },
                    [
                      _c("apexchart", {
                        attrs: {
                          type: "line",
                          height: "350",
                          options: _vm.chartOptions,
                          series: _vm.series,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 col-xl-4" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "card-body px-0" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "list-group scrollbar-1",
                      staticStyle: { height: "300px" },
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass:
                            "list-group-item list-group-item-action border-0 d-flex align-items-center justify-content-between py-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "list-left d-flex align-items-center",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center rounded svg-icon w-45px h-45px bg-primary text-white mr-2",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-lightning-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d: "M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "list-content" }, [
                                _c(
                                  "span",
                                  { staticClass: "list-title text-body" },
                                  [_vm._v("Total Products")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "text-muted d-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.thisWeekProducts) +
                                        " New Products"
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.totalProducts))]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass:
                            "list-group-item list-group-item-action border-0 d-flex align-items-center justify-content-between py-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "list-left d-flex align-items-center",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center rounded svg-icon w-45px h-45px bg-secondary text-white mr-2",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-bar-chart-line-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d: "M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "list-content" }, [
                                _c(
                                  "span",
                                  { staticClass: "list-title text-body" },
                                  [_vm._v("Total Sales")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "text-muted d-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.thisWeekSales) + " New Sales"
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.totalSales))]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass:
                            "list-group-item list-group-item-action border-0 d-flex align-items-center justify-content-between py-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "list-left d-flex align-items-center",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center rounded svg-icon w-45px h-45px bg-secondary text-white mr-2",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-bar-chart-line-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d: "M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "list-content" }, [
                                _c(
                                  "span",
                                  { staticClass: "list-title text-body" },
                                  [_vm._v("Total Order")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "text-muted d-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.thisWeekOrders) + " New Sales"
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.totalOrders))]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass:
                            "list-group-item list-group-item-action border-0 d-flex align-items-center justify-content-between py-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "list-left d-flex align-items-center",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center rounded svg-icon w-45px h-45px bg-warning text-white mr-2",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-lightning-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d: "M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "list-content" }, [
                                _c(
                                  "span",
                                  { staticClass: "list-title text-body" },
                                  [_vm._v("Total Users")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "text-muted d-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.thisWeekCustomer) +
                                        " New Users"
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.totalCustomers))]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$parent.permissions.includes("dashboard")
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "card-body pt-3" }, [
                  _c(
                    "div",
                    { attrs: { id: "chart" } },
                    [
                      _c("apexchart", {
                        attrs: {
                          type: "line",
                          height: "350",
                          options: _vm.saleChartOptions,
                          series: _vm.saleSeries,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$parent.permissions.includes("dashboard")
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "kt-table-content table-responsive" },
                      [
                        _c("Order", {
                          on: { "update-parent-props": _vm.updateParentProps },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "notfound", staticStyle: { "margin-top": "200px" } },
      [_c("p", [_vm._v("You Don't Have Permission To View Dashboard")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header align-items-center border-0" },
      [
        _c("div", { staticClass: "card-title mb-0" }, [
          _c(
            "h3",
            { staticClass: "card-label text-body font-weight-bold mb-0" },
            [_vm._v("Users")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header align-items-center border-0" },
      [
        _c("div", { staticClass: "card-title mb-0" }, [
          _c(
            "h3",
            { staticClass: "card-label text-body font-weight-bold mb-0" },
            [_vm._v("\n              Last Update\n            ")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header align-items-center border-0" },
      [
        _c("div", { staticClass: "card-title mb-0" }, [
          _c(
            "h3",
            { staticClass: "card-label font-weight-bold mb-0 text-body" },
            [_vm._v("\n              Monthly Sales\n            ")]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }