var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subheader py-2 py-lg-6 subheader-solid" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
        _c("ol", { staticClass: "breadcrumb bg-white mb-0 px-0 py-2" }, [
          _c(
            "li",
            {
              staticClass: "breadcrumb-item active",
              attrs: { "aria-current": "page" },
            },
            [
              _vm._v("Dashboard "),
              _c("strong", [_vm._v(" v " + _vm._s(_vm.version.setting_value))]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }