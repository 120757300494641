var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Default Notification")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notification.default_notification,
              expression: "notification.default_notification",
            },
          ],
          ref: "default_notification",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.notification.default_notification },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.notification,
                "default_notification",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Onesignal App Id")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notification.onesignal_app_id,
              expression: "notification.onesignal_app_id",
            },
          ],
          ref: "onesignal_app_id",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.notification.onesignal_app_id },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.notification,
                "onesignal_app_id",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Onsignal Sender Id")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notification.onsignal_sender_id,
              expression: "notification.onsignal_sender_id",
            },
          ],
          ref: "onsignal_sender_id",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.notification.onsignal_sender_id },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.notification,
                "onsignal_sender_id",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Firebase Api Key")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notification.firebase_api_key,
              expression: "notification.firebase_api_key",
            },
          ],
          ref: "firebase_api_key",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.notification.firebase_api_key },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.notification,
                "firebase_api_key",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Firebase Auth Domain")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notification.firebase_auth_domain,
              expression: "notification.firebase_auth_domain",
            },
          ],
          ref: "firebase_auth_domain",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.notification.firebase_auth_domain },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.notification,
                "firebase_auth_domain",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Firebase Database Url")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notification.firebase_database_url,
              expression: "notification.firebase_database_url",
            },
          ],
          ref: "firebase_database_url",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.notification.firebase_database_url },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.notification,
                "firebase_database_url",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Firebase Project Id")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notification.firebase_project_id,
              expression: "notification.firebase_project_id",
            },
          ],
          ref: "firebase_project_id",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.notification.firebase_project_id },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.notification,
                "firebase_project_id",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Firebase Storage Bucket")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notification.firebase_storage_bucket,
              expression: "notification.firebase_storage_bucket",
            },
          ],
          ref: "firebase_storage_bucket",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.notification.firebase_storage_bucket },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.notification,
                "firebase_storage_bucket",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Firebase Sender Id")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notification.firebase_sender_id,
              expression: "notification.firebase_sender_id",
            },
          ],
          ref: "firebase_sender_id",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.notification.firebase_sender_id },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.notification,
                "firebase_sender_id",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.updateSetting()
            },
          },
        },
        [_vm._v("\n      Submit\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }