var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.loading
      ? _c("div", { staticClass: "se-pre-con" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _c("header", { staticClass: "pos-header bg-white" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _c("div", { staticClass: "col-xl-3 col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "greeting-text" }, [
              _c(
                "h3",
                {
                  staticClass: "card-label mb-0 font-weight-bold text-primary",
                },
                [_vm._v("\n              WELCOME\n            ")]
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "card-label mb-0" }, [
                _vm._v(
                  "\n              " + _vm._s(_vm.login_name) + "\n            "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xl-4 col-lg-4 col-md-6 clock-main" },
            [_c("DigitalClock")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-xl-5 col-lg-5 col-md-6 order-lg-last order-second",
            },
            [
              _c("div", { staticClass: "topbar justify-content-end" }, [
                _c("div", { staticClass: "dropdown mega-dropdown" }),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown mega-dropdown" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column selectmain mr-3" },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedWarehouse,
                              expression: "selectedWarehouse",
                            },
                          ],
                          staticClass: "arabic-select select-down",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedWarehouse = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.taxApply()
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Warehouse"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.warehouses, function (warehouse) {
                            return _c(
                              "option",
                              {
                                domProps: {
                                  value: {
                                    id: warehouse.warehouse_id,
                                    text: warehouse.warehouse_name,
                                  },
                                  selected:
                                    warehouse.warehouse_id ==
                                    _vm.selectedWarehouse.id,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(warehouse.warehouse_name) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.errors.has("warehouse_id")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.get("warehouse_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown mega-dropdown" }, [
                  _c(
                    "div",
                    {
                      staticClass: "topbar-item",
                      attrs: {
                        id: "id2",
                        "data-toggle": "dropdown",
                        "data-display": "static",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn btn-icon w-auto h-auto btn-clean d-flex align-items-center py-0 mr-3",
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-dashboard btn-primary",
                              staticStyle: {
                                padding: "0.375rem 0.75rem !important",
                              },
                              attrs: { to: "/admin/dashboard" },
                            },
                            [_vm._v("Dashboard")]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-dashboard-responsive btn-primary",
                              staticStyle: {
                                padding: "0.375rem 0.75rem !important",
                              },
                              attrs: { to: "/admin/dashboard" },
                            },
                            [_vm._v("D")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "topbar-item",
                      attrs: {
                        id: "id2",
                        "data-toggle": "dropdown",
                        "data-display": "static",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn btn-icon w-auto h-auto btn-clean d-flex align-items-center py-0 mr-3",
                          on: {
                            click: function ($event) {
                              _vm.toggle_calculator = !_vm.toggle_calculator
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "symbol symbol-35 symbol-light-success",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "symbol-label bg-primary font-size-h5",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-calculator-fill",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "20px",
                                        height: "20px",
                                        fill: "#fff",
                                        viewBox: "0 0 16 16",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 .5v2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5zm0 4v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM4 12.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM7 9.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM10 6.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-1z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.toggle_calculator
                    ? _c(
                        "div",
                        {
                          staticClass: "calu",
                          staticStyle: { "min-width": "248px" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "calculator" },
                            [
                              _c("Calculator"),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticStyle: {
                                    float: "right",
                                    top: "4px",
                                    right: "10px",
                                    position: "absolute",
                                    background: "transparent",
                                    border: "none",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.toggle_calculator = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    X\n                  "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "topbar-item folder-data" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn btn-icon w-auto h-auto btn-clean d-flex align-items-center py-0 mr-3",
                      attrs: {
                        "data-toggle": "modal",
                        "data-target": "#folderpop",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showDraftOrderModal()
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "badge badge-pill badge-primary" },
                        [_vm._v(_vm._s(_vm.draftOrders.length))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "symbol symbol-35 symbol-light-success",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "symbol-label bg-warning font-size-h5",
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "20px",
                                    height: "20px",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "#ffff",
                                    viewBox: "0 0 16 16",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "div",
                    {
                      staticClass: "topbar-item",
                      attrs: {
                        "data-toggle": "dropdown",
                        "data-display": "static",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn btn-icon w-auto h-auto btn-clean d-flex align-items-center py-0",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "symbol symbol-35 symbol-light-success",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "symbol-label font-size-h5" },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-person-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d: "M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-right",
                      staticStyle: { "min-width": "150px" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "svg-icon svg-icon-xl svg-icon-primary mr-2",
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "feather feather-power",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M18.36 6.64a9 9 0 1 1-12.73 0",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("line", {
                                    attrs: {
                                      x1: "12",
                                      y1: "2",
                                      x2: "12",
                                      y2: "12",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                  Logout\n                "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "contentPOS" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-4 order-xl-first order-last" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between colorfull-select",
                    },
                    [
                      _c("div", { staticClass: "selectmain" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.select_category,
                                expression: "select_category",
                              },
                            ],
                            staticClass: "arabic-select w-150px bag-primary",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.select_category = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  return _vm.getProduct()
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "all" } }, [
                              _vm._v("All"),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.categories, function (category) {
                              return [
                                _c(
                                  "option",
                                  { domProps: { value: category.id } },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          category.detail
                                            ? category.detail[0].name
                                            : ""
                                        ) +
                                        "\n                      "
                                    ),
                                  ]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "selectmain" }, [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "pagination pagination-sm m-0 float-right",
                          },
                          [
                            _c(
                              "li",
                              {
                                class: [
                                  { disabled: !_vm.pagination.prev_page_url },
                                ],
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getProduct(
                                          _vm.pagination.prev_page_url
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Previous")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("li", { staticClass: "disabled" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link text-dark",
                                  attrs: { href: "javascript:;" },
                                },
                                [
                                  _vm._v(
                                    "Page " +
                                      _vm._s(_vm.pagination.current_page) +
                                      " of\n                        " +
                                      _vm._s(_vm.pagination.last_page)
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "page-item",
                                class: [
                                  { disabled: !_vm.pagination.next_page_url },
                                ],
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getProduct(
                                          _vm.pagination.next_page_url
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Next")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "product-items" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.category_products, function (category_product) {
                      return _c(
                        "div",
                        {
                          staticClass:
                            "col-xl-4 col-lg-2 col-md-3 col-sm-4 col-6",
                        },
                        [
                          _c("div", { staticClass: "productCard" }, [
                            _c("div", { staticClass: "productThumb" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "javascript:;",
                                    attr: category_product.product_combination_id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addProductInList(
                                        category_product.product_id,
                                        category_product.product_combination_id,
                                        category_product.product_type
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "img-fluid",
                                    attrs: {
                                      src: "/gallary/" + category_product.image,
                                      alt: "ix",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "productContent" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "javascript:;",
                                    attr: category_product.product_combination_id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addProductInList(
                                        category_product.product_id,
                                        category_product.product_combination_id,
                                        category_product.product_type
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(category_product.product_name) +
                                      "\n                      "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-5 col-lg-8 col-md-8" }, [
            _c("div", {}, [
              _c(
                "div",
                {
                  staticClass:
                    "card card-custom gutter-b bg-white border-0 table-contentpos",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between colorfull-select",
                      },
                      [
                        _c("div", { staticClass: "selectmain" }, [
                          _c("label", { staticClass: "text-dark d-flex" }, [
                            _vm._v("Choose a Customer\n                      "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-secondary white rounded-circle",
                                attrs: {
                                  "data-toggle": "modal",
                                  "data-target": "#choosecustomer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showCustomerModel()
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "svg-sm",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      "xmlns:xlink":
                                        "http://www.w3.org/1999/xlink",
                                      version: "1.1",
                                      id: "Layer_122",
                                      x: "0px",
                                      y: "0px",
                                      width: "512px",
                                      height: "512px",
                                      viewBox: "0 0 512 512",
                                      "enable-background": "new 0 0 512 512",
                                      "xml:space": "preserve",
                                    },
                                  },
                                  [
                                    _c("g", [
                                      _c("rect", {
                                        attrs: {
                                          x: "234.362",
                                          y: "128",
                                          width: "43.263",
                                          height: "256",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("rect", {
                                        attrs: {
                                          x: "128",
                                          y: "234.375",
                                          width: "256",
                                          height: "43.25",
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedCustomer,
                                  expression: "selectedCustomer",
                                },
                              ],
                              staticClass: "arabic-select select-down",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedCustomer = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.selectCustomerAddress()
                                  },
                                ],
                              },
                            },
                            _vm._l(_vm.customers, function (customer) {
                              return _c(
                                "option",
                                {
                                  domProps: {
                                    value: {
                                      id: customer.customer_id,
                                      text: customer.customer_first_name,
                                    },
                                    selected:
                                      _vm.selectedCustomer.id ==
                                      customer.customer_id,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(customer.customer_first_name) +
                                      "\n                        " +
                                      _vm._s(customer.customer_last_name) +
                                      "\n                        " +
                                      _vm._s(customer.customer_id) +
                                      "\n                      "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _vm.errors.has("customer_id")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.get("customer_id")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "selectmain" }, [
                          _c("label", { staticClass: "text-dark d-flex" }, [
                            _vm._v(
                              "Choose a Customer Address\n                      "
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-secondary white rounded-circle",
                                attrs: {
                                  "data-toggle": "modal",
                                  "data-target": "#choosecustomer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showCustomerAddressModel()
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "svg-sm",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      "xmlns:xlink":
                                        "http://www.w3.org/1999/xlink",
                                      version: "1.1",
                                      id: "Layer_122",
                                      x: "0px",
                                      y: "0px",
                                      width: "512px",
                                      height: "512px",
                                      viewBox: "0 0 512 512",
                                      "enable-background": "new 0 0 512 512",
                                      "xml:space": "preserve",
                                    },
                                  },
                                  [
                                    _c("g", [
                                      _c("rect", {
                                        attrs: {
                                          x: "234.362",
                                          y: "128",
                                          width: "43.263",
                                          height: "256",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("rect", {
                                        attrs: {
                                          x: "128",
                                          y: "234.375",
                                          width: "256",
                                          height: "43.25",
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedCustomerAddress,
                                  expression: "selectedCustomerAddress",
                                },
                              ],
                              staticClass: "arabic-select select-down",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedCustomerAddress = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            _vm._l(
                              _vm.customer_address,
                              function (customeraddress) {
                                return _c(
                                  "option",
                                  {
                                    domProps: {
                                      value: customeraddress,
                                      selected:
                                        _vm.selectedCustomerAddress.id ==
                                        customeraddress.id,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(customeraddress.street_address) +
                                        "\n                      "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _vm.errors.has("customer_address")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.get("customer_address")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card card-custom gutter-b bg-white border-0 table-contentpos",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group row mb-0" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("label", [_vm._v("Select Product")]),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          {
                            staticClass:
                              "form-group mb-0 d-flex barcodeselection",
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sortBy,
                                    expression: "sortBy",
                                  },
                                ],
                                staticClass: "form-control w-25",
                                attrs: { id: "exampleFormControlSelect1" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.sortBy = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "name" } }, [
                                  _vm._v("Name"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "sku" } }, [
                                  _vm._v("SKU"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "form-control border-dark",
                              class: _vm.sortBy == "name" ? "" : "d-none",
                              attrs: {
                                list: "browsers",
                                type: "text",
                                id: "basicInput1",
                                placeholder:
                                  "Type any 4 Characters and Press Enter",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.getSearchDataDetail()
                                },
                                keyup: function ($event) {
                                  return _vm.searchProduct(
                                    $event,
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "datalist",
                              { attrs: { id: "browsers" } },
                              _vm._l(
                                _vm.searchFilters,
                                function (searchFilter) {
                                  return _c(
                                    "option",
                                    {
                                      attrs: {
                                        product: searchFilter.product,
                                        product_id: searchFilter.product_id,
                                        product_combination_id:
                                          searchFilter.product_combination_id,
                                      },
                                      domProps: {
                                        value: searchFilter.product_name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(searchFilter.product_name) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "form-control border-dark",
                              class: _vm.sortBy == "name" ? "d-none" : "",
                              attrs: {
                                list: "browsers1",
                                type: "text",
                                id: "basicInput2",
                                placeholder:
                                  "Type any 4 Characters and Press Enter",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.getSearchDataDetail()
                                },
                                keyup: function ($event) {
                                  return _vm.searchProduct(
                                    $event,
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "datalist",
                              { attrs: { id: "browsers1" } },
                              _vm._l(
                                _vm.searchFilters,
                                function (searchFilter) {
                                  return _c(
                                    "option",
                                    {
                                      attrs: {
                                        product: searchFilter.product,
                                        product_id: searchFilter.product_id,
                                        product_combination_id:
                                          searchFilter.product_combination_id,
                                      },
                                      domProps: {
                                        value: searchFilter.product
                                          ? searchFilter.product.sku
                                          : "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(
                                            searchFilter.product
                                              ? searchFilter.product.sku
                                              : ""
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-datapos" }, [
                    _c(
                      "div",
                      {
                        staticClass: "table-responsive",
                        attrs: { id: "printableTable" },
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "display",
                            staticStyle: { width: "100%" },
                            attrs: { id: "orderTable" },
                          },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.add_to_cart_products,
                                function (add_to_cart_product, index) {
                                  return _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(add_to_cart_product.product_name)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(add_to_cart_product.price)),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("input", {
                                        ref: "qtyRef" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control border-dark w-100px posqty",
                                        attrs: {
                                          type: "number",
                                          placeholder: "",
                                        },
                                        domProps: {
                                          value: add_to_cart_product.qty,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.qtyInc(
                                              index,
                                              add_to_cart_product.product_id,
                                              add_to_cart_product.product_combination_id,
                                              $event.target.value,
                                              add_to_cart_product.product_type
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          add_to_cart_product.discount_show
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(add_to_cart_product.subtotal)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-toolbar text-right",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "confirm-delete",
                                              attrs: {
                                                href: "javascript:;",
                                                title: "Delete",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeProduct(
                                                    add_to_cart_product.product_id,
                                                    add_to_cart_product.product_combination_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-trash-alt",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group row mb-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-12 btn-submit d-flex justify-content-end",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger mr-2 confirm-delete",
                              attrs: { type: "submit", title: "Delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.emptyValue()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fas fa-trash-alt mr-2" }),
                              _vm._v(
                                "\n                      Suspand/Cancel\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary white",
                              attrs: { type: "submit" },
                              on: {
                                click: function ($event) {
                                  return _vm.draftOrder()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "bi bi-folder-fill svg-sm mr-2",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "currentColor",
                                    viewBox: "0 0 16 16",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(
                                "\n                      Draft Order\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-3 col-lg-4 col-md-4" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "shop-profile" }, [
                    _c("div", { staticClass: "media" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "bg-primary w-100px h-100px d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("h2", { staticClass: "mb-0 white" }, [
                            _vm._v(_vm._s(_vm.businessName.charAt(0))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "media-body ml-3" }, [
                        _c("h3", { staticClass: "title font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.businessName)),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "phoonenumber" }, [
                          _vm._v(
                            "\n                      " +
                              _vm._s(_vm.businessPhone) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "adddress" }, [
                          _vm._v(
                            "\n                      " +
                              _vm._s(_vm.businessAddress) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resulttable-pos" }, [
                    _c("table", { staticClass: "table right-table" }, [
                      _c("tbody", [
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between",
                          },
                          [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "border-0 font-size-h5 mb-0 font-size-bold text-dark",
                              },
                              [
                                _vm._v(
                                  "\n                        Total Items\n                      "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-dark font-size-base",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.add_to_cart_products.length) +
                                    "\n                      "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between",
                          },
                          [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "border-0 font-size-h5 mb-0 font-size-bold text-dark",
                              },
                              [
                                _vm._v(
                                  "\n                        Actual Total Price\n                      "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-dark font-size-base",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.add_to_cart_products
                                        .reduce(
                                          (acc, item) =>
                                            parseFloat(acc) +
                                            parseFloat(item.actual_price),
                                          0
                                        )
                                        .toFixed(2)
                                    ) +
                                    "\n                      "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("th", { staticClass: "border-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center font-size-h5 mb-0 font-size-bold text-dark",
                                },
                                [
                                  _vm._v(
                                    "\n                          DISCOUNT (" +
                                      _vm._s(
                                        (
                                          ((_vm.add_to_cart_products.reduce(
                                            (acc, item) =>
                                              parseFloat(acc) +
                                              parseFloat(item.actual_price),
                                            0
                                          ) -
                                            _vm.add_to_cart_products.reduce(
                                              (acc, item) =>
                                                parseFloat(acc) +
                                                parseFloat(item.subtotal),
                                              0
                                            )) /
                                            _vm.add_to_cart_products.reduce(
                                              (acc, item) =>
                                                parseFloat(acc) +
                                                parseFloat(item.actual_price),
                                              0
                                            )) *
                                          100
                                        ).toFixed(2)
                                      ) +
                                      "\n                          %)\n                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-dark font-size-base",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      (
                                        _vm.add_to_cart_products.reduce(
                                          (acc, item) =>
                                            parseFloat(acc) +
                                            parseFloat(item.actual_price),
                                          0
                                        ) -
                                        _vm.add_to_cart_products.reduce(
                                          (acc, item) =>
                                            parseFloat(acc) +
                                            parseFloat(item.subtotal),
                                          0
                                        )
                                      ).toFixed(2)
                                    ) +
                                    "\n                      "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between",
                          },
                          [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "border-0 font-size-h5 mb-0 font-size-bold text-dark",
                              },
                              [
                                _vm._v(
                                  "\n                        Subtotal\n                      "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-dark font-size-base",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.add_to_cart_products
                                        .reduce(
                                          (acc, item) =>
                                            parseFloat(acc) +
                                            parseFloat(item.subtotal),
                                          0
                                        )
                                        .toFixed(2)
                                    ) +
                                    "\n                      "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("th", { staticClass: "border-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center font-size-h5 mb-0 font-size-bold text-dark",
                                },
                                [
                                  _vm._v(
                                    "\n                          Tax (" +
                                      _vm._s(
                                        _vm.add_to_cart_products.reduce(
                                          (acc, item) =>
                                            parseFloat(acc) +
                                            parseFloat(item.subtotal),
                                          0
                                        ) > 0
                                          ? _vm.tax_per_apply
                                          : 0
                                      ) +
                                      ") %\n                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-dark font-size-base",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      (
                                        (_vm.add_to_cart_products.reduce(
                                          (acc, item) =>
                                            parseFloat(acc) +
                                            parseFloat(item.subtotal),
                                          0
                                        ) /
                                          100) *
                                        parseFloat(_vm.tax_per_apply)
                                      ).toFixed(2)
                                    ) +
                                    "\n                      "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between item-price",
                          },
                          [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "border-0 font-size-h5 mb-0 font-size-bold text-primary",
                              },
                              [
                                _vm._v(
                                  "\n                        TOTAL\n                      "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-primary font-size-base",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      (
                                        _vm.add_to_cart_products.reduce(
                                          (acc, item) =>
                                            parseFloat(acc) +
                                            parseFloat(item.subtotal),
                                          0
                                        ) +
                                        (_vm.add_to_cart_products.reduce(
                                          (acc, item) =>
                                            parseFloat(acc) +
                                            parseFloat(item.subtotal),
                                          0
                                        ) /
                                          100) *
                                          parseFloat(_vm.tax_per_apply)
                                      ).toFixed(2)
                                    ) +
                                    "\n                      "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-center flex-column buttons-cash",
                    },
                    [
                      _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary white mb-2",
                            attrs: {
                              href: "javascript:;",
                              "data-toggle": "modal",
                              "data-target": "#payment-popup",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveTransaction()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-money-bill-wave mr-2",
                            }),
                            _vm._v(
                              "\n                    Pay With Cash\n                  "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-none" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-outline-secondary",
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.showDraftOrderModal()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-credit-card mr-2" }),
                            _vm._v(
                              "\n                    Pay With Card\n                  "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop",
      class: _vm.discountModel == 1 ? "show" : "d-none",
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-left modal",
        class: _vm.discountModel == 1 ? "d-block" : "d-none",
        attrs: { role: "dialog", "aria-modal": "true", tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showDiscountModel()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", { staticClass: "text-body" }, [
                    _vm._v("Discount"),
                  ]),
                  _vm._v(" "),
                  _c("fieldset", { staticClass: "form-group mb-3 d-flex" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dicount_per,
                          expression: "dicount_per",
                        },
                      ],
                      staticClass: "form-control bg-white",
                      attrs: {
                        type: "text",
                        name: "text",
                        id: "exampleInputText",
                        "aria-describedby": "textHelp",
                        placeholder: "Enter Discount",
                      },
                      domProps: { value: _vm.dicount_per },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.dicount_per = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn-secondary btn ml-2 white pt-1 pb-1 d-flex align-items-center justify-content-center",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.discountApply()
                          },
                        },
                      },
                      [_vm._v("Apply")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop",
      class: _vm.taxModel == 1 ? "show" : "d-none",
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-left modal",
        class: _vm.taxModel == 1 ? "d-block" : "d-none",
        attrs: { role: "dialog", "aria-modal": "true", tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showTaxModel()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", { staticClass: "text-body" }, [_vm._v("Tax")]),
                  _vm._v(" "),
                  _c("fieldset", { staticClass: "form-group mb-3 d-flex" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tax_val,
                          expression: "tax_val",
                        },
                      ],
                      staticClass: "form-control bg-white",
                      attrs: {
                        type: "text",
                        name: "text",
                        id: "exampleInputText",
                        "aria-describedby": "textHelp",
                        placeholder: "Enter Tax",
                      },
                      domProps: { value: _vm.tax_val },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.tax_val = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn-secondary btn ml-2 white pt-1 pb-1 d-flex align-items-center justify-content-center",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.taxApply()
                          },
                        },
                      },
                      [_vm._v("Apply")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop show",
      class: _vm.draftModel == 1 ? "show" : "d-none",
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-left modal",
        class: _vm.draftModel == 1 ? "d-block" : "d-none",
        attrs: { role: "dialog", "aria-modal": "true", tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(4),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showDraftOrderModal()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "modal-body pos-ordermain" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-12" },
                    _vm._l(_vm.draftOrders, function (draftOrder, index) {
                      return _c("div", { staticClass: "pos-order col-lg-4" }, [
                        _c(
                          "h3",
                          {
                            staticClass: "pos-order-title",
                            attrs: { id: "myModalLabel1" },
                          },
                          [
                            _vm._v(
                              "\n                    Order " +
                                _vm._s(index + 1) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "orderdetail-pos" }, [
                          _c("p", [
                            _c("strong", [_vm._v("Customer Name ")]),
                            _vm._v(
                              _vm._s(draftOrder.customer_name) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._m(5, true),
                          _vm._v(" "),
                          _c("p", [
                            _c("strong", [_vm._v("Total Items ")]),
                            _vm._v(
                              "\n                      " +
                                _vm._s(
                                  draftOrder.product
                                    ? draftOrder.product.length
                                    : 0
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _c("strong", [_vm._v("Amount to Pay ")]),
                            _vm._v(
                              " $" +
                                _vm._s(
                                  draftOrder.product
                                    ? draftOrder.product.reduce(
                                        (acc, item) =>
                                          acc + parseFloat(item.subtotal),
                                        0
                                      ) +
                                        +(
                                          (draftOrder.product.reduce(
                                            (acc, item) =>
                                              acc + parseFloat(item.subtotal),
                                            0
                                          ) /
                                            100) *
                                          parseFloat(
                                            draftOrder.dicount_per_apply
                                          )
                                        ) +
                                        +(
                                          draftOrder.product.reduce(
                                            (acc, item) =>
                                              parseFloat(acc) +
                                              parseFloat(item.subtotal),
                                            0
                                          ) / 100
                                        ) *
                                          +parseFloat(draftOrder.tax_per_apply)
                                    : 0
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "confirm-delete ml-3",
                                attrs: { href: "javascript:;", title: "edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editDraft(draftOrder.id)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-edit" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "confirm-delete ml-3",
                                attrs: {
                                  href: "javascript:;",
                                  title: "Delete",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDraft(draftOrder.id)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-trash-alt" })]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(6),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-left modal",
        class: _vm.customerModel == 1 ? "d-block" : "d-none",
        attrs: { role: "dialog", "aria-modal": "true", tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(7),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showCustomerModel()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("First Name"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_info.first_name,
                            expression: "customer_info.first_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "first_name",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter First Name",
                        },
                        domProps: { value: _vm.customer_info.first_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_info,
                              "first_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("first_name")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("first_name")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Last Name"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_info.last_name,
                            expression: "customer_info.last_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "last_name",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Last Name",
                        },
                        domProps: { value: _vm.customer_info.last_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_info,
                              "last_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("last_name")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("last_name")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Email"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_info.email,
                            expression: "customer_info.email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "email",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Email",
                        },
                        domProps: { value: _vm.customer_info.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_info,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("email")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("email")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Password"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_info.password,
                            expression: "customer_info.password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          name: "password",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Password",
                        },
                        domProps: { value: _vm.customer_info.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_info,
                              "password",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("password")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("password")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row justify-content-end mb-0" },
                  [
                    _c("div", { staticClass: "col-md-6 text-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              return _vm.saveCustomer()
                            },
                          },
                        },
                        [_vm._v("Add Customer")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop",
      class: _vm.customerModel == 1 ? "show" : "d-none",
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-left modal",
        class: _vm.customerAddressModel == 1 ? "d-block" : "d-none",
        attrs: { role: "dialog", "aria-modal": "true", tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(8),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showCustomerAddressModel()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("First Name"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_address_fields.first_name,
                            expression: "customer_address_fields.first_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "first_name",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter First Name",
                        },
                        domProps: {
                          value: _vm.customer_address_fields.first_name,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_address_fields,
                              "first_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("first_name")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("first_name")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Last Name"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_address_fields.last_name,
                            expression: "customer_address_fields.last_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "last_name",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Last Name",
                        },
                        domProps: {
                          value: _vm.customer_address_fields.last_name,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_address_fields,
                              "last_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("last_name")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("last_name")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Postal Code"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_address_fields.postcode,
                            expression: "customer_address_fields.postcode",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "postcode",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Postal Code",
                        },
                        domProps: {
                          value: _vm.customer_address_fields.postcode,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_address_fields,
                              "postcode",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("postcode")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("postcode")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [_vm._v("City")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_address_fields.city,
                            expression: "customer_address_fields.city",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "city",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter City",
                        },
                        domProps: { value: _vm.customer_address_fields.city },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_address_fields,
                              "city",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("city")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("city")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Country "),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.customer_address_fields.country_id,
                              expression: "customer_address_fields.country_id",
                            },
                          ],
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.customer_address_fields,
                                  "country_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.fetchStates()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.countries, function (country) {
                          return _c(
                            "option",
                            { domProps: { value: country.country_id } },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(country.country_name) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.errors.has("country_id")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("country_id")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("State "),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.customer_address_fields.state_id,
                              expression: "customer_address_fields.state_id",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.customer_address_fields,
                                "state_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.states, function (state) {
                          return _c(
                            "option",
                            { domProps: { value: state.id } },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(state.name) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.errors.has("state_id")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("state_id")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Street Address"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_address_fields.street_address,
                            expression:
                              "customer_address_fields.street_address",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "street_address",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Steet Address",
                        },
                        domProps: {
                          value: _vm.customer_address_fields.street_address,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_address_fields,
                              "street_address",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("street_address")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.get("street_address")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Company"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_address_fields.company,
                            expression: "customer_address_fields.company",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "company",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Company",
                        },
                        domProps: {
                          value: _vm.customer_address_fields.company,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_address_fields,
                              "company",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("company")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("company")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Lat Lng"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_address_fields.latlong,
                            expression: "customer_address_fields.latlong",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "latlong",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter LatLng",
                        },
                        domProps: {
                          value: _vm.customer_address_fields.latlong,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_address_fields,
                              "latlong",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("latlong")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("latlong")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Phone"),
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_address_fields.phone,
                            expression: "customer_address_fields.phone",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "phone",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Phone",
                        },
                        domProps: { value: _vm.customer_address_fields.phone },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer_address_fields,
                              "phone",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("phone")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("phone")),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row justify-content-end mb-0" },
                  [
                    _c("div", { staticClass: "col-md-6 text-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              return _vm.saveCustomerAddress()
                            },
                          },
                        },
                        [_vm._v("Add Customer Address")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop",
      class: _vm.customerAddressModel == 1 ? "show" : "d-none",
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pre-loader" }, [
      _c("img", {
        staticClass: "img-fluid",
        attrs: { src: "/assets/images/loadergif.gif", alt: "loading" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Price")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Discount Price")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Subtotal")]),
        _vm._v(" "),
        _c("th", {
          staticClass: "text-right no-sort",
          staticStyle: { width: "5%" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-title h4" }, [
      _c("h3", { staticClass: "modal-title", attrs: { id: "myModalLabel1" } }, [
        _vm._v("Add Discount"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-title h4" }, [
      _c("h3", { staticClass: "modal-title", attrs: { id: "myModalLabel1" } }, [
        _vm._v("Add Tax"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-title h4" }, [
      _c("h3", { staticClass: "modal-title", attrs: { id: "myModalLabel1" } }, [
        _vm._v("Draft Orders"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Payment Status ")]),
      _vm._v("Pending"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer border-0 modal-footer" }, [
      _c("div", { staticClass: "row" }, [_c("div", { staticClass: "col-12" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-title h4" }, [
      _c("h3", { staticClass: "modal-title", attrs: { id: "myModalLabel1" } }, [
        _vm._v("Add Customer"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-title h4" }, [
      _c("h3", { staticClass: "modal-title", attrs: { id: "myModalLabel1" } }, [
        _vm._v("\n              Add Customer Address\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }